import useTranslation from "next-translate/useTranslation"
import Link from "next/link"
import { useRouter } from "next/router"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"

import BaseLayout from "@components/BaseLayout"
import { AppState } from "@redux/reducer"
import { selectIsAuthenticated } from "@redux/reducer/auth"
import { Routes } from "@services/routes"

import TranslatedHtml from "./common/TranslatedHtml"

interface IProps {
  error?: string
  errorTranslationParams?: Record<string, string> // belongs to the error translation for giving optionally additional parameters
  statusCode?: number
  title?: string
}

/**
 * This component provides an error message to the user
 */
const ErrorPage: React.FC<IProps> = ({ error, errorTranslationParams, statusCode, title }: IProps) => {
  const { t } = useTranslation("common")

  statusCode = statusCode || 500
  title = title || `error:title.${statusCode}`
  error = error ? `error:${error}` : `error:explanation.${statusCode}`

  const isAuthenticated = useSelector((state: AppState) => selectIsAuthenticated(state))

  return <BaseLayout pageTitle={t(title)}>
    <Row>
      <Col className="text-center">
        <h1>{t(title)}</h1>
        <p>
          <TranslatedHtml content={error} params={errorTranslationParams} />
        </p>
        <div className="btn-flex-layout">
          <Link href={'#'}>
            <a className="btn primary-btn" title={t("goto.reload")} onClick={useRouter().reload}>{t("goto.reload")}</a>
          </Link>
          {isAuthenticated
            ? <Link href={Routes.MyProjects}>
              <a className="btn primary-btn" title={t("goto.myProjects")}>{t("goto.myProjects")}</a>
            </Link>
            : <Link href={Routes.Login}>
              <a className="btn primary-btn" title={t("goto.login")}>{t("goto.login")}</a>
            </Link>
          }
          <Link href={Routes.Home}>
            <a className="btn primary-btn" title={t("goto.home")}>{t("goto.home")}</a>
          </Link>
        </div>
      </Col>
    </Row>
  </BaseLayout>
}

export default ErrorPage
