
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import ErrorPage from "@components/ErrorPage";
// @todo use Sentry here as in _error.tsx to log 404s?
const Error404Page: React.FC = () => <ErrorPage statusCode={404}/>;
export default Error404Page;

    async function __Next_Translate__getStaticProps__18d1324ebd9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18d1324ebd9__ as getStaticProps }
  